import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";

export const Routes: RouteConfig[] = [

  /* ADMIN PAGES */
  { moduleId: PLATFORM.moduleName("./pages/admin/admin"),                           route: ['admin'],  title: 'Admin', nav: true,},
  { moduleId: PLATFORM.moduleName("./pages/admin/adminpeerserver/adminpeerserver"), route: ["admin/adminpeerserver"], name: "adminpeerserver", title: "adminpeerserver", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/admin/adminclient/adminclient"),         route: ["admin/adminclient"], name: "adminclient", title: "adminclient", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/admin/adminhome/adminhome"),             route: ["admin/adminhome"], name: "adminhome", title: "adminhome", auth: true, nav: false, settings: { roles: ["admin"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/admin/admin_test/admin_test"),           route: ["admin/admin_test"], name: "admin_test", title: "admin_test", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/admin/adminseeblog/adminseeblog"),       route: ["admin/adminseeblog"], name: "adminseeblog",title: "admin_see_blog", auth: true,nav: false,settings: { roles: ["admin"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/admin/adminblog/adminblog"),             route: ["admin/adminblog"], title: "redaction_blog_page", name: "adminblog", auth: true, nav: false, settings: { roles: ["admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/admin/admineditblog/admineditblog"),     route: ["admin/admineditblog"], name: "admineditblog", title: "admin_edit_blog", auth: true, nav: false, settings: { roles: ["admin"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/admin/adminpage/adminpage"),             route: ["admin/adminpage"], name: "adminpage", title: "adminpage", auth: true, nav: false, settings: { roles: ["admin"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/admin/admin_report_bank/admin_report_bank"), route: ["admin/admin_report_bank"], name: "admin_report_bank", title: "admin_report_bank", auth: true, nav: false, settings: { roles: ["admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/admin/adminreport/adminreport"),             route: ["admin/adminreport"], name: "adminreport", title: "adminreport", auth: true, nav: false, settings: { roles: ["admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/admin/adminbetaregister/adminbetaregister"), route: ["admin/adminbetaregister"], name: "adminbetaregister", title: "adminbetaregister", auth: true, nav: false, settings: { roles: ["admin"] },}, 
  
  // ASSET PAGES
  { moduleId: PLATFORM.moduleName("./pages/assets/assets"),            route: ["assets"], title: "Digital Assets", name: "assets", nav: true, auth: true, settings: { roles: ["user", "admin", "free"] },},
  //{ moduleId: PLATFORM.moduleName("./pages/assets/collection_home/collection_home"), route: ["collection_home"], name: "collection_home", title: "Collection", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] },}, 
  /* /ORDINALS ASSETS */
  { moduleId: PLATFORM.moduleName("./pages/assets/assets"), route: ["assets"], title: "assets.tabtitle", name: "assets_home", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/assets/collection_id/collection_id"), route: ["assets/collection_id"], title: "Collection", name: "collection_id", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/assets/nft_details/nft_details"), route: ["assets/nft_details"], title: "Details", name: "nft_details", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/assets/redeem/redeem"), route: ["assets/redeem"], title: "Redeem Or Send", name: "redeem", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/assets/redeem/success/success"), route: ["assets/redeem/success"], title: "Success!", name: "success", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/assets/edit/edit"), route: ["assets/edit"], title: "Edit", name: "edit", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/assets/address/address"), route: ["assets/address"], title: "Redeem", name: "redeem", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/assets/payment/payment"), route: ["assets/payment"], title: "Checkout", name: "assets_payment", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/assets/payment/comment/comment"), route: ["assets/payment/comment"], title: "Commentary", name: "comment", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/assets/payment/recap/recap"), route: ["assets/payment/recap"], title: "Recapitulation", name: "recap", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/assets/payment/success/success"), route: ["assets/payment/success"], title: "Success!", name: "success", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },  
  /* AUTH PAGES */
  // { moduleId: PLATFORM.moduleName("./pages/auth/auth"),                                route: ["auth"], title: "Authentification", name: "auth", nav: true, auth: false, settings: { roles: [] },},
  // { moduleId: PLATFORM.moduleName("./pages/auth/signin/signin"),                       route: ["signin"], title: "auth.signin.tabtitle", name: "signin",  auth: false, nav: true, settings: { roles: [] },},
  { moduleId: PLATFORM.moduleName("./pages/auth/signin/intermediate/intermediate"),    route: ["auth/signin/intermediate"], title: "auth.signin.tabtitle_intermediate", name: "signin_intermediate",  auth: false, nav: false, settings: { roles: [] },}, 
  { moduleId: PLATFORM.moduleName("./pages/auth/login/login"),                         route: ["login"], title: "Login", name: "login",   auth: false, nav: true, settings: { roles: [] }, },
  { moduleId: PLATFORM.moduleName("./pages/auth/logout/logout"),                       route: ["logout"], title: "Logout", name: "logout",   auth: true, nav: true, settings: { roles: ["user", "free", "admin"] }, },
  { moduleId: PLATFORM.moduleName("./pages/auth/register/register"),                   route: ["register"], name: "register", title: "Register", auth: false, nav: true, settings: { roles: [] },}, 
  { moduleId: PLATFORM.moduleName("./pages/auth/forgot/forgot"),                       route: ["forgot"], title: "Forgot Password", name: "forgot", auth: false, nav: true, settings: { roles: [] },}, 
  { moduleId: PLATFORM.moduleName("./pages/auth/forgot/confirmed/confirmed"),          route: ["forgot_confirmed"], name: "forgot_confirmed", title: "Confirmed", auth: false, nav: true, settings: { roles: [] },}, 
  { moduleId: PLATFORM.moduleName("./pages/auth/redirect"),                            route: ["redirect"], title: "Redirection", name: "redirect", auth: false, nav: true, settings: { roles: [] },}, 
  { moduleId: PLATFORM.moduleName("./pages/auth/reset_password/reset_password"),       route: ["reset_password"], name: "reset_password", title: "Reset your password", auth: false, nav: true, settings: { roles: [] },}, 
  { moduleId: PLATFORM.moduleName("./pages/auth/verify/verify"),                       route: ["verify"], name: "verify", title: "Verify", auth: false, nav: true, settings: { roles: ["user", "free", "admin"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/auth/please_verify/please_verify"),         route: ["please_verify"], title: "Please Verify", name: "please_verify", auth: true, nav: false, settings: { roles: ["user", "free", "admin"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/auth/verify_mail/verify_mail"),             route: ["verify_mail"], name: "verify_mail", title: "Verify your mail", auth: true, nav: false, settings: { roles: ["user", "free", "admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/auth/change_password/change_password"),     route: ["change_password"], title: "change_password", name: "change_password", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },  
  { moduleId: PLATFORM.moduleName("./pages/auth/change_password/confirmed/confirmed"), route: ["change_password_confirmed"], name: "change_password_confirmed", title: "Confirmed", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, }, 
 
  /* FIRSTTIME PAGES */
  { moduleId: PLATFORM.moduleName("./pages/auth/firsttime/ftwelcome/ftwelcome"),  route: ["firsttime/ftwelcome"], name: "ftwelcome", title: "Welcome", auth: false, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/auth/firsttime/ftintro/ftintro"),      route: ["firsttime/ftintro"],   title: "Intro", name: "ftintro", auth: false, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/auth/firsttime/ftfriends/ftfriends"),  route: ["firsttime/ftfriends"], title: "Friends", name: "ftfriends", auth: false, nav: true, settings: { roles: ["user", "admin", "free"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/auth/firsttime/fttheme/fttheme"),      route: ["firsttime/fttheme"], name: "fttheme", title: "Theme", auth: false, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/auth/firsttime/ftprofilepic/ftprofilepic"),     route: ["firsttime/ftprofilepic"], name: "ftprofilepic", title: "Profile Picture", auth: false, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/auth/firsttime/ftnotification/ftnotification"), route: ["firsttime/ftnotification"], name: "ftnotification", title: "Notification", auth: false, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/auth/firsttime/ftphone/ftphone"),      route: ["firsttime/ftphone"], title: "auth.firsttime.tabtitle_phone", name: "ftphone", auth: false, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/auth/firsttime/ft_create_paymail/ft_create_paymail" ), route: ["firsttime/ft_create_paymail"], name: "ft_create_paymail", title: "Paymail", auth: false, nav: true, settings: { roles: ["user", "admin", "free"] },  },     
      
  /* BANK PAGES */
  { moduleId: PLATFORM.moduleName("./pages/bank/bank"),                     route: ["bank"], title: "Bank", name: "bank", nav: true, auth: true, settings: { roles: [] },},
  { moduleId: PLATFORM.moduleName("./pages/bank/add_account/add_account"),  route: ["bank/add_account"], name: "add_account", title: "bank.add_account.tabtitle", auth: true, nav: false, settings: { roles: ["user", "free", "admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/bank/agreement/users/users"),             route: ["bank/agreements_users"], title: "bank.agreement.tabtitle_agreements_users", name: "agreements_users", auth: true, nav: true, settings: { roles: ["user", "free", "admin"] },  }, 
  { moduleId: PLATFORM.moduleName("./pages/bank/agreement/users_login/users_login"), route: ["bank/agreements_users_login"], title: "Agreements Login", name: "agreements_users_login", auth: true, nav: true, settings: { roles: ["user", "free", "admin"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/bank/agreement/success/success"),         route: ["bank/agreement/success"], title: "Success", name: "agreement_success", auth: true, nav: true, settings: { roles: ["user", "free", "admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/bank/agreement/failure/failure"),         route: ["bank/agreement/failure"], name: "agreement_failure", title: "Agreement Failure", auth: true, nav: true, settings: { roles: ["user", "free", "admin"] },},
  { moduleId: PLATFORM.moduleName("./pages/bank/agreement/intermediate/intermediate"),    route: ["bank/agreement/intermediate"], name: "agreement_landing", title: "Loading...", auth: true, nav: true, settings: { roles: ["user", "free", "admin"] },},     
  { moduleId: PLATFORM.moduleName("./pages/bank/choose_country_list/choose_country_list"),       route: ["bank/choose_country_list"], title: "bank.choose_country_list1", name: "choose_country_list", auth: true,nav: true,settings: { roles: ["user", "free", "admin"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/bank/choose_institution/choose_institution"),         route: ["bank/choose_institution"], title: "bank.choose_institution.tabtitle", name: "choose_institution", auth: true, nav: true, settings: { roles: ["user", "free", "admin"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/bank/reauthorize_bank/reauthorize_bank"), route: ["bank/reauthorize"], name: "reauthorize_bank", title: "Reauthorize", auth: true, nav: true, settings: { roles: ["user", "free", "admin"] }, }, 

    // BANK PATHS THAT NEED TO BE RETHINKED
    { moduleId: PLATFORM.moduleName("./pages/bank/savepot/savepot_home/savepot_home"),  route: ["bank/savepot/home"],              title: "bank.savepot.home.tabtitle",     name: "savepot_home", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] },}, 
    { moduleId: PLATFORM.moduleName("./pages/bank/savepot/savepot_add/savepot_add"),    route: ["bank/savepot/add"],               title: "bank.savepot.add.tabtitle",      name: "savepot_add", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] },}, 
    { moduleId: PLATFORM.moduleName("./pages/bank/savepot/savepot_id/savepot_id"),      route: ["bank/savepot/id/:saving_pot_id"], title: "bank.savepot.home.id.tabtitle",  name: "savepot_id", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, }, 
    { moduleId: PLATFORM.moduleName("./pages/bank/payment/intermediate/intermediate"),      route: ["bank/payment/intermediate"], title: "Loading...",  name: "payment_landing", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, }, 
    { moduleId: PLATFORM.moduleName("./pages/bank/create_recipients/create_recipients"),                        route: ["bank/create_recipients"],            title: "Create beneficiary",           name: "create_recipients", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, }, 
    { moduleId: PLATFORM.moduleName("./pages/bank/generate_qr_code/generate_qr_code"),                 route: ["bank/generate_qr_code"], title: "QR-Code", name: "generate_qr_code", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, }, 
    { moduleId: PLATFORM.moduleName("./pages/bank/generate_qr_code_receive/generate_qr_code_receive"), route: ["bank/generate_qr_code_receive"], title: "QR Code Receive", name: "generate_qr_code_receive", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, }, 
    { moduleId: PLATFORM.moduleName("./pages/bank/transactions_details/transactions_details"),         route: ["bank/transactions_details"], title: "Transactions Details", name: "transactions_details", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, }, 
  
  /* BITCOIN PAGES */
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/bitcoin"), route: ["bitcoin"], title: "Bitcoin", name: "bitcoin", auth: true, nav: true,  settings: { roles: [] },},
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/wallet/add_account_bitcoin/add_account_bitcoin"), route: ["bitcoin/wallet/add"],       title: "bitcoin.wallet.add.tabtitle", name: "add_account_bitcoin", auth: true, nav: true, settings: { roles: ['user', 'free', 'admin'] }},
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/wallet/name_bitcoin_wallet/name_bitcoin_wallet"), route: ["bitcoin/name_wallet"],      title: "bitcoin.name_bitcoin_wallet.tabtitle", name: "name_bitcoin_wallet", nav: true, auth: true, settings: { roles: [] },}, 
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/wallet/create_key/bitcoin_account_create_key"), route: ["bitcoin/wallet/create_key"],  title: "bitcoin.wallet.create_key.tabtitle", name: "bitcoin_account_create_key", auth: true, nav: false, settings: { roles: ["user", "free", "admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/wallet/import/bitcoin_import"),            route: ["bitcoin/wallet/import"],           title: "bitcoin.wallet.import.tabtitle", name: "bitcoin_import", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/wallet/success/bitcoin_account_success"),  route: ["bitcoin/wallet/success"],          title: "bitcoin.wallet.success.tabtitle", name: "bitcoin_account_success", auth: true,nav: false,settings: { roles: ["user", "free", "admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/wallet/import/confirmed/confirmed"),       route: ["bitcoin/wallet/import_confirmed"], title: "bitcoin.wallet.import_confirmed.tabtitle", name: "bitcoin_import_confirmed", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/topup/topup"),                             route: ["bitcoin/topup"],                   title: "BSV Top-ups", name: "bitcoin_top_up", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/topup/intermediate/intermediate"),       route: ["bitcoin/topup/intermediate"], title: "Bitcoin Top Up", name: "Loading...", auth: true,nav: true,settings: { roles: ["user", "admin", "free"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/topup/success/bitcoin_top_up_accepted"), route: ["bitcoin/topup/success"], title: "Congratulations!",name: "bitcoin_top_up_accepted",auth: true,nav: false,settings: { roles: ["user", "admin", "free"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/topup/failure/bitcoin_top_up_refused"),  route: ["bitcoin/topup/failure"], title: "Oops", name: "bitcoin_top_up_refused",auth: true,nav: false,settings: { roles: ["user", "admin", "free"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/bitcoin_tx_currency/bitcoin_tx_currency"), route: ["bitcoin_tx_currency"], title: "BitcoinTxCurrency", name: "bitcoin_tx_currency", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/bitcoin_generate_qr_code/bitcoin_generate_qr_code"), route: ["bitcoin/generate_qr_code"], title: "Bitcoin QR Code", name: "bitcoin_generate_qr_code", auth: true,nav: false,settings: { roles: ["user", "admin", "free"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/payment/receive/receive"), route: ["bitcoin/payment/receive"], title: "Receive Bitcoin",name: "bitcoin_receive", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/bitcoin/transactions_details_bitcoin/transactions_details_bitcoin"), route: ["bitcoin/transactions_details_bitcoin"], title: "Transactions Details Bitcoin", name: "transactions_details_bitcoin", auth: true, nav: true,settings: { roles: ["user", "admin", "free"] },}, 

  /* BLOG PAGES */
  { moduleId: PLATFORM.moduleName("./pages/blog/blog"), route: ["blog"], title: "blogs.tabtitle", name: "blog", auth: false, nav: true, settings: { roles: [] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/blog/blogId/blogId"), route: ["blogid"], title: "Article", name: "blogId", auth: false, nav: true, settings: { roles: [] },}, 
 
  /* CONTACT PAGES */
  { moduleId: PLATFORM.moduleName("./pages/contacts/contacts"), route: ["contacts/:page"], name: "contacts", title: "Contacts", auth: true, href: "/mine", settings: { roles: ["user", "admin", "free"] },}, 
  
  /* HOME PAGES */
  { moduleId: PLATFORM.moduleName("./pages/home/home"),                       route: ['home'],                 title: 'home.tabtitle',                  name: "home",                  auth: true,  nav: true, settings: { roles: ["user", "free", "admin"] }, },
  { moduleId: PLATFORM.moduleName("./pages/home/surprise/surprise"),                       route: ['surprise'],                 title: 'Surprise',                  name: "surprise",                  auth: true,  nav: true, settings: { roles: ["user", "free", "admin"] }, },
  { moduleId: PLATFORM.moduleName("./pages/home/about/about"),                    route: ['about'],                name: "about",     title: "About",            auth: false, nav: true, settings: { roles: [] },},
  { moduleId: PLATFORM.moduleName("./pages/home/contact_us/contact_us"),          route: ["contact_us"],            name: "contact_us", title: "contact", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] },},
  { moduleId: PLATFORM.moduleName("./pages/home/contact_us/confirmed/confirmed"), route: ["contact_us/confirmed"],  name: "contact_us_confirmed", title: "Confirmed", auth: false, nav: false, settings: { roles: ["user", "admin", "free"] },},
  { moduleId: PLATFORM.moduleName("./pages/home/feedback/feedback"),              route: ["feedback"],             title: "Feedback",              name: "feedback",           auth: false, nav: true, settings: { roles: [] },}, 
  // { moduleId: PLATFORM.moduleName("./pages/home/install/install"),                route: ["install"],               title: "home.install",          name: "install",  auth: false, nav: true, settings: { roles: [] }, },
  { moduleId: PLATFORM.moduleName("./pages/home/install/success/success"),        route: ["install/success"],       title: "home.install.success",  name: "install_success",  auth: false, nav: true, settings: { roles: [] }, },
  { moduleId: PLATFORM.moduleName("./pages/home/install/iphone/iphone"),          route: ["install/iphone"],        title: "home.install.iphone",   name: "install_iphone", auth: false, nav: true, settings: { roles: [] },},
  { moduleId: PLATFORM.moduleName("./pages/home/not-found/not-found"),            route: ["not-found"],             name: "not-found", title: "404 - Not found",  auth: false, nav: false, settings: { roles: [] },},
  { moduleId: PLATFORM.moduleName("./pages/home/transactions/transactions"),      route: ["transactions"],         name: "transactions", title: "Transactions", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/home/transactions/comment/comment"),   route: ["transaction/comments"],         name: "transaction_comments", title: "Transaction Comments", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/home/welcome/confirmed/confirmed"),    route: ["welcome_confirmed"],     name: "welcome_confirmed", title: "What's that page?", auth: false, nav: true, settings: { roles: [] },},
  
  /* LEGAL PAGES */
  { moduleId: PLATFORM.moduleName("./pages/legal/legal"),                 route: ["legal"],             title: "legal.tabtitle",             name: "legal", auth: false, nav: true, settings: { roles: [] },},
  { moduleId: PLATFORM.moduleName("./pages/legal/conditions/conditions"), route: ["legal/conditions"],  title: "legal.conditions.tabtitle",  name: "conditions",  auth: false, nav: true, settings: { roles: [] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/legal/privacy/privacy"),       route: ["legal/privacy"],     title: "legal.privacy.tabtitle",     name: "privacy",  auth: false, nav: true, settings: { roles: [] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/legal/terms/terms"),           route: ["legal/terms"],       title: "legal.terms.tabtitle",       name: "terms", auth: false, nav: true,  settings: { roles: [] },},

  /* PAYMENT PAGE NO DISTINCTION BETWEEN BITCOIN AND BANKS */
  { moduleId: PLATFORM.moduleName("./pages/payments/payments"),             route: ["payments"],                   title: "payments.tabtitle",      name: "payments", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] },},
  { moduleId: PLATFORM.moduleName("./pages/payments/recap/payments_recap"), route: ["payments/recap"], title: "payments.recap.tabtitle", name: "payments_recap", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] },},
  
  /* PROFILE PAGES */
  // { moduleId: PLATFORM.moduleName("./pages/profile/account_overview/account_overview"),   route: ["account_overview"], title: "Account Overview", name: "account_overview",  auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },  
  { moduleId: PLATFORM.moduleName("./pages/profile/profile"),                             route: ["profile"], name: "profile", title: "Profile", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/profile/notifications/notifications"),         route: ["notifications"], title: "profile.notifications.tabtitle", name: "notifications", auth: true,nav: false,settings: { roles: ["user", "admin", "free"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/profile/pin_code/pin_code"),                   route: ["pin_code"], name: "pin_code", title: "PIN Code", auth: false, nav: true, settings: { roles: [] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/pin_code/pin_code_actual/pin_code_actual"),       route: ["pin_code_actual"], name: "pin_code_actual", title: "Actual pin code", auth: false, nav: true, settings: { roles: [] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/pin_code/pin_code_create/pin_code_create"),       route: ["pin_code/create"], name: "pin_code_create", title: "profile.pin_code.tabtitle_create", auth: false, nav: true, settings: { roles: ["user", "admin", "free"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/pin_code/pin_code_create/pin_code_confirmed/pin_code_confirmed"), route: ["pin_code/confirmed"], name: "pin_code_confirmed", title: "Confirmed", auth: true,nav: true, settings: { roles: ["user", "admin", "free"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/choose_currency_format/choose_currency_format"), route: ["choose_currency_format"], name: "choose_currency_format", title: "profile.choose_currency_format.title", auth: false, nav: true, settings: { roles: [] },  }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/choose_language/choose_language"), route: ["choose_language"], name: "choose_language", title: "profile.choose.choose_language_title", auth: false, nav: true, settings: { roles: [] },  }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/subscription/choose_subscription/choose_subscription"), route: ["choose_subscription"], name: "choose_subscription", title: "Subscriptions", auth: true, settings: { roles: ["user", "free", "admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/choose_type_account/choose_type_account" ),  route: ["profile/choose_type_account"], name: "choose_type_account",title: "profile.choose.choose_type_account.tabtitle" , auth: true, nav: false, settings: { roles: ["user", "free", "admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/scan_qr_code/scan_qr_code"),  route: ["scan_qr_code"], title: "Scan", name: "scan_qr_code", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/subscription/subscription"),                 route: ["subscription"], title: "Subscription", name: "subscription", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/subscription/subscription_finished/subscription_finished"), route: ["subscription_finished"], name: "subscription_finished", title: "Subscription Finished", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] }, },  
  { moduleId: PLATFORM.moduleName("./pages/profile/subscription/intermediate/intermediate"), route: ["subscription/intermediate"], name: "subscription_landing", title: "Loading ...", auth: false, nav: false, settings: { roles: ["user", "free", "admin"] },}, 
  { moduleId: PLATFORM.moduleName("./pages/profile/subscription/failure/failure"),  route: ["subscription_failure"], name: "subscription_failure", title: "Subscription Failure", auth: false, nav: false, settings: { roles: ["user", "free", "admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/subscription/success/success"),  route: ["subscription_success"], name: "subscription_success", title: "Subscription successful", auth: false, nav: false, settings: { roles: ["user", "free", "admin"] }, }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/choose_wallet/choose_wallet"),   route: ["choose_wallet"], name: "choose_wallet", title: "Wallets", auth: true, settings: { roles: ["user", "free", "admin"] }, },
  { moduleId: PLATFORM.moduleName("./pages/profile/profile_delete/profile_delete"), route: ["profile_delete"], name: "profile_delete", title: "Are you sure?", auth: true, nav: true, settings: { roles: ["user", "admin", "free"] } }, 
  { moduleId: PLATFORM.moduleName("./pages/profile/edit_profile/edit_profile"),     route: ["edit_profile"], name: "edit_profile", title: "edit_profile", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, },
  { moduleId: PLATFORM.moduleName("./pages/profile/choose_phone_country_list/choose_phone_country_list"), route: ["choose_phone_country_list"], name: "choose_phone_country_list", title: "Choose country list", auth: true, nav: false, settings: { roles: ["user", "free", "admin"] }, }, 

  /* /test - TEST PAGES */
  { moduleId: PLATFORM.moduleName("./pages/test/test"), route: ["test"], name: "test",  title: "Testings", auth: false, nav: true, settings: { roles: ["user", "admin", "free"] },}, 
// { moduleId: PLATFORM.moduleName("./pages/profile/generate_referral_code/generate_referral_code" ), route: ["generate_referral_code"], title: "Generate Referral Code", name: "generate_referral_code", auth: true, nav: false, settings: { roles: ["user", "admin", "free"] }, 

 
]
